@font-face {
  font-family: 'chakra_petchregular';
  src: url('/fonts/chakrapetch-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'chakra_petchregular';
  }

  body {
    margin: 0;
    padding: 0;
  }
  
  h1 {
    color: #333;
    text-align: center;
  }
  
  h2 {
    color: #4caf50;
    text-align: center;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 8px;
  }

  i{
    color: #0aac29;
    font-size: 1.05rem;

  }
  
  .gray-box {
    background-color: #95959581;
    padding: 15px;
    border-radius: 5px;
  }
  .green-box {
    background-color: #0aac2830;
    padding: 15px;
    border-radius: 5px;
  }
  
  /*commissions */
  .commissions-container {
    max-width: 800px;
    margin: auto;
    padding: 30px;
    line-height: 1.5;
  }
  
  .commissions-section {
    margin-bottom: 40px;
    margin-top: 40px;
    text-align: center;
   }

  .commissions-section h3 {
    padding-bottom: 8px;
    color: #333;
  }
  
  .home,
  .services,
   .sign-up {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
/* programa.js */
.programa-container {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  line-height: 1.5;
}

.time-schedule-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  margin-bottom: 20px;
}

.time-schedule-table th, .time-schedule-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  white-space: normal;
  overflow: hidden;
  word-wrap: break-word;
}

.time-schedule-table th {
  background-color: #f2f2f2;
}

.special-row {
  background-color: rgba(10, 172, 40, 0.2);
}
/* Reduza a largura da coluna "Entidade" */
.time-schedule-table td:nth-child(4) {
  max-width: 200px;;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.8rem;
}

/* Estilos específicos para a página de palestrantes (SpeakersPage) */
.speakers-container {
  max-width: 800px;
  margin: auto;
  padding: 30px;
}

.speaker-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.speaker-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin: 0 auto 10px;
  border: 5px solid #0aac29;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.speaker-name {
  color: #333;
  font-size: 1.5rem;
  margin-top: 10px;
}

.speaker-description {
  color: #666;
  font-size: 1.2rem;
  margin-top: 12px;
  line-height: 1.4;
}

.speaker-image:hover {
  transform: scale(1.1);
}

.speaker-summary {
  color: #333;
  font-size: 1.1rem;
  margin-top: 10px;
  line-height: 1.5;
  text-align: justify;
}


/* Estilos específicos para a página ExhibitionPage */
.ExhibitionPage-container {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  line-height: 2;
}

.ExhibitionPage-container p {
  margin-bottom: 20px;
  text-align: justify;
}

/* Estilos específicos para a página RegulationPage */

.regulation-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.regulation-container h1 {
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
}

.regulation-container h2 {
  font-size: 1.5em;
  color: #555;
  margin-top: 30px;
  margin-bottom: 15px;
}

.regulation-container p {
  font-size: 1.1em;
  line-height: 1.5;
  color: #666;
  margin-bottom: 15px;
  text-align: justify;
}

.regulation-container ul,
.rregulation-container ol {
  margin-bottom: 20px;
}

.regulation-container li {
  font-size: 1.1em;
  line-height: 1.5;
  color: #666;
  margin-bottom: 10px;
}

.regulation-container a {
  color: #15ff00;
  text-decoration: underline;
}

.regulation-container a:hover {
  color:  #15ff00;
}

@media screen and (max-width: 768px) {
  .ExhibitionPage-container {
    padding: 20px;
  }
  .time-schedule-table {
   margin-right: 100px !important;
   }
  .time-schedule-table th, .time-schedule-table td {
    font-size: 0.8rem; 
  }
  .time-schedule-table td:nth-child(4) {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
