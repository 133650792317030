.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 80%; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .modal h2 {
    color: #0aac29;
  }
  
  .modal p {
    color: #000;
  }
  
  .modal li {
    color: #000;
    text-align: left;
  }

  .modal button {
    background-color: #0aac29;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .modal button.close-btn {
    background-color: #808080;
  }
  

  

  
  