.footer-container {
  background-color: #242424;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: 'PF Video Pro', sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap; 
  justify-content: center;
}


.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #0aac29 !important; 
  transition: color 0.3s ease; 
}


.footer-link-items .contact-number {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-link-items .phone-number {
  font-size: 18px; 
  color: rgb(255, 255, 255); 
}

.footer-link-items .call-info {
  font-size: 10px;
  color: #808080;
}



.footer-link-wrapper {
  display: flex;
  flex: 1; 
  margin: 16px; 
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 100%; 
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup_inner {
  position: relative;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  max-width: 500px;
  max-height: 700px;
  text-align: center;
}

.popup_inner img {
  width: 80%;
  height: auto;
}

.popup_inner button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
/* Estilos para o link "Como Chegar" */
.input-areas {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: auto;
}



a.how-to-get {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

a.how-to-get:hover {
  color: #0aac29;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .footer-link-items {
    margin: 16px 0;
    width: 100%;
    text-align: center;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

.footer-signature {
  text-align: center;
  margin-top: 10px;
  color: #5c5b5b; 
}

.footer-signature p {
  font-size: 14px;
  margin: 0;
  font-weight: bold;
}

.footer-signature a {
  color: #0aac28af; 
  text-decoration: none;
  font-weight: bold;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .footer-link-items {
    margin: 16px 0;
    width: 100%;
    text-align: center;
  }

  .social-media-wrap {
    flex-direction: column;
  }
  .footer-link-items {
    align-items: center;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

