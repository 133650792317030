@font-face {
  font-family: 'pf_videotext_proregular';
  src: url('/fonts/pf_videotext_pro_regular-webfont.woff') format('woff2'),
       url('/fonts/pf_videotext_pro_regular-webfont.woff2') format('woff');
  font-weight: normal;
  font-style: normal;

}
.countdown-container {
  display: flex;
  justify-content: space-around;
  color: #f8f9f7;
  overflow: auto;
  font-size: 2vw;
}

.countdown-item {
  text-align: center;
  margin: 0 10px;
}

@media screen and (max-width: 768px) {
  .countdown-container {
    flex-direction: unset;
    font-size: 3vw; 
}
  .countdown-item {
    margin: 0 5px;
  }
}