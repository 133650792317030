@font-face {
  font-family: 'pf_videotext_proregular';
  src: url('/fonts/pf_videotext_pro_regular-webfont.woff') format('woff2'),
       url('/fonts/pf_videotext_pro_regular-webfont.woff2') format('woff');
  font-weight: normal;
  font-style: normal;

}
    
  .hero-container {
    background: url('/public/images/capa.jpg') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    color: white;
  }

  .hero-container > h1 {
    color:aliceblue;
    font-size: 80px;
    margin-top: -100px;
    font-weight: lighter;
    font-family:'pf_videotext_proregular'; 
   
     }

  .hero-container > p {
    margin-top: 8px;
    color: #0aac29;
    font-size: 40px;
    font-family:'Chakra Peach'; 
    font-style: italic;
    font-weight: 900;
    
      }
    .green-border {
      border: 1px solid #0aac29; 
      border-radius: 15px;
      border-width: 10px;
      padding: 10px;
      background-color:  #0aac29;
      margin-right: 20px;   
      font-family:'pf_videotext_proregular'; 
      font-size: 1.5rem;
    }
    
    .green-border-group {
      display: flex;
      margin-top: 20px; 
    }

  .hero-btns {
    margin-top: 15px;
  }

  .hero-btns > button {
    margin-top: 32px;
  }

.hero-btns .btn {
  margin: 6 px;
}

.fa-play-circle {
  margin-right: 8px;
}

.subtitle {
  font-size: 1.5rem;
  margin-top: 5px;
  font-family:'pf_videotext_proregular'; 
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 60px;
    margin-top: -150px;
  }
  .subtitle {
    font-size: 18px;
  }
  .green-border {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 40px;
    margin-top: -150px;
  }
  .hero-container > p {
    font-size: 30px;
  }
  .btn-mobile {
    display: block;
    text-decoration: none;
  }
  .btn {
    width: 100%;
  }
  .subtitle {
    font-size: 18px;
  }

}