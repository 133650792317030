/* Sponsors container */
.sponsors-container {
    text-align: center;
    background-color: #333;
    padding: 20px;
}

/* List of sponsors */
.sponsors-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.sponsors-list-name {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
/* Sponsor component */
.sponsor {
    margin: 20px;
    flex: 0 0 12%;
    aspect-ratio: 1/1;

}

/* Image for each sponsor */
.sponsor img {
    width:100%;
    height:100%;
    object-fit: contain;
    border-radius: 0%;
    box-shadow: 0px 10px 30px -5px rgb(0, 0, 0);
    transition: all 0.3s ease;
    padding: 5px;
    transition: transform 0.3s ease-in-out;
}

.sponsor img:hover {
    transform: scale(1.3);

}

/* Name for each sponsor */
.sponsor h2 {
    margin-top: 1em;
    font-size: 1.5em;
    color: #333;
}

.sponsor h2 a {
    font-size: 1em;
    color: #ffffff;
}

.sponsor p {
    font-size: 1.6em;
    color: #ffffff;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    object-fit: contain;
    box-shadow: 0px 10px 30px -5px rgb(0, 0, 0);

}

/* Safari specific styles */
@media not all and (min-resolution:.001dpcm) {
    .sponsor img {
      width: auto;
      max-width: 100%;
      height: auto;
      object-fit: contain;
    }
    .sponsor p {
    font-size: 0.5em;
    color: #ffffff;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    object-fit: contain;
    box-shadow: 0px 10px 30px -5px rgb(0, 0, 0);
  }
}


  @media screen and (max-width: 768px) {
    .sponsor {
      width: 30%; 
    }
    .sponsor p {
    font-size: 1em;
    color: #ffffff;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    object-fit: contain;
    box-shadow: 0px 10px 30px -5px rgb(0, 0, 0);
    
  }
  }
