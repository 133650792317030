.cookie-consent {
    z-index: 1000;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #333;
    color: #fff;
    padding: 15px;
    text-align: center;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  }
  
  .cookie-consent p {
    margin: 0;
  }
  
  .cookie-consent button {
    background-color: #0aac29;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .cookie-consent button:hover {
    background-color: #0aac29;
  }

  .cookie-consent .more-info-link {
    background-color: #8d939d; 
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
    text-decoration: none; 
    display: inline-block;
    transition: background-color 0.3s ease;
  }
  
  .cookie-consent .more-info-link {
    background-color: #8b8b8e; 
  }
  
  /* Animations */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .cookie-consent {
    animation: fadeInUp 0.5s ease-out;
  }
  